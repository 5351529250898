import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
  useState,
} from "react";
import toast from "react-hot-toast";
import {
  InputSearchAndDropdown,
  type LabelAndValuesType,
} from "~/components/ui/input-search-dropdown";
import { api } from "~/utils/api";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogClose,
  DialogHeader,
  DialogFooter,
} from "../ui/dialog";
import { Button } from "~/components/ui/button";

interface ListEntity {
  id: string;
  type: "OUTFIT" | "OUTFIT_PRODUCT" | "PRODUCT";
}

interface Context {
  openListDialog: (entity: ListEntity) => void;
}

const UserListsContext = createContext<Context | undefined>(undefined);

export function useUserListsCtx() {
  const context = useContext(UserListsContext);
  if (!context) {
    throw new Error("useUserLists must be used within a UserListsProvider");
  }
  return context;
}

interface Props {
  children: React.ReactNode;
}

export const UserListsProvider = ({ children }: Props) => {
  const { userLists, refetch, create, addToLists } = useUserListsApi();
  const [isOpen, setIsOpen] = useState(false);
  const [entity, setEntity] = useState<ListEntity | null>(null);
  const [selectedLists, setSelectedLists] = useState<LabelAndValuesType[]>([]);

  const onSubmit = async () => {
    if (!entity) return;

    const existingListIds = new Set(userLists.map((l) => l.value));
    const nonExistingLists = selectedLists.filter(
      (l) => !existingListIds.has(l.value),
    );

    if (nonExistingLists.length > 0) {
      await create.mutateAsync(nonExistingLists);
    }

    await addToLists.mutateAsync({
      listIds: selectedLists.map((l) => l.value),
      entity_id: entity.id,
      entity_type: entity.type,
    });

    toast.success("Item added to list(s)");
    await refetch();
  };

  return (
    <UserListsContext.Provider
      value={{
        openListDialog: (entity) => {
          setEntity(entity);
          setIsOpen(true);
        },
      }}
    >
      {children}
      <Dialog
        open={isOpen}
        onOpenChange={(v) => {
          if (!v) {
            setSelectedLists([]);
            setEntity(null);
          }
          setIsOpen(v);
        }}
      >
        <ListsDialog
          lists={userLists}
          selected={selectedLists}
          setSelected={setSelectedLists}
          onSubmit={onSubmit}
        />
      </Dialog>
    </UserListsContext.Provider>
  );
};

interface ListsDialogContentProps {
  lists: LabelAndValuesType[];
  selected: LabelAndValuesType[];
  setSelected: Dispatch<SetStateAction<LabelAndValuesType[]>>;
  onSubmit: () => void;
}

const ListsDialog = ({
  lists,
  selected,
  setSelected,
  onSubmit,
}: ListsDialogContentProps) => {
  return (
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Select or Create List</DialogTitle>
        <DialogDescription>
          Add this item to an existing list or create a new list. Click save
          when you're done.
        </DialogDescription>
      </DialogHeader>
      <div className="py-4">
        <InputSearchAndDropdown
          list={lists}
          selectedListValues={selected}
          setSelectedListValues={setSelected}
        />
      </div>
      <DialogFooter>
        <DialogClose asChild>
          <Button onClick={onSubmit}>Save changes</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
};

function useUserListsApi() {
  const { data, refetch } = api.lists.getListsForUser.useQuery();

  const create = api.lists.createLists.useMutation({});
  const addToLists = api.lists.addItemToLists.useMutation({});

  return {
    userLists: (data ?? []).map((ul) => ({
      value: ul.list_id,
      label: ul.name,
    })),
    refetch,
    create,
    addToLists,
  };
}
