var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/error-monitor";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"957d1dd17d19e77f755478cf2150c6bafba4ddc7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  debug: false,

  dsn: "https://f5aa77c8aea245468d13ca0964bc8628@sentry.34.244.183.88.sslip.io/1",
  tunnel: "/error-monitor",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
});
