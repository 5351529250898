import { z } from "zod";
import { fetchBE } from "~/lib/api_be/fetch";
import { getBoundingBox } from "~/utils/bounding-box";

const DerivedFeaturesSchema = z.object({
  colour: z.string().nullish(),
  pattern: z.string().nullish(),
  wearing_style: z.string().nullish(),
  material: z.string().nullish(),
  clothing_type: z.string().nullish(),
  price_category: z.string().nullish(),
});

const UserImageSegmentSchema = z.object({
  id: z.string(),
  derived_features: DerivedFeaturesSchema.nullable(),
  bounding_box: z.array(z.number()).transform(getBoundingBox),
});

const UserImageSchema = z.object({
  image_url: z.string(),
  products: z.array(UserImageSegmentSchema).optional(),
  pipe_stage: z.string(),
});

export async function segment(id: string) {
  const data = await fetchBE.get(`/ml/segment/${id}`);
  return UserImageSchema.parse(data);
}

export async function text(text: string) {
  const data = await fetchBE.post("/ml/embed/text", { text });
  return data as number[];
}

export async function textBatch(items: string[]) {
  // TODO: batch endpoint
  // return (await fetchPOST("/ml/embed/text-batch", { texts })) as number[][];
  return Promise.all(items.map(text));
}
