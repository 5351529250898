import { useSession } from "next-auth/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { PREF_CHECKS } from "~/components/onboard/pref-checks";
import { type Currency, getCurrency } from "~/lib/currencies";
import { type RouterOutputs, api } from "~/utils/api";
import { useUserCountry } from "~/lib/hooks";

export type DbPrefsType = RouterOutputs["user"]["preferences"];

type PrefsCtx = {
  currency: Currency;
  prefs?: DbPrefsType;
  edited: Partial<DbPrefsType>;
  warnings: number;
  edit: (key: keyof DbPrefsType, value: any) => void;
  resetEdits: () => void;
  mutate: () => void;
};

const DbPrefsCtx = createContext<PrefsCtx | null>(null);

interface Props {
  children: React.ReactNode;
}

export const DbPrefsProvider = ({ children }: Props) => {
  const { status } = useSession();
  const { data, refetch } = api.user.preferences.useQuery(undefined, {
    refetchOnWindowFocus: false,
    enabled: status === "authenticated",
  });
  const [edited, setEdited] = useState<Partial<DbPrefsType>>({});
  const warnings = useMemo(() => {
    if (!data) return 0;

    // missing photo_urls should not be a warning
    const { photo_urls, ...PREF_CHECKS_WITHOUT_PHOTO_URLS } = PREF_CHECKS;

    return Object.values(PREF_CHECKS_WITHOUT_PHOTO_URLS).filter((fails) =>
      fails(data),
    ).length;
  }, [data]);

  const mutation = api.user.setPreference.useMutation({});

  const mutate = async () => {
    if (!edited) return;
    await Promise.all(
      Object.keys(edited).map((key) =>
        mutation.mutateAsync({
          preferenceName: key as keyof DbPrefsType,
          value: edited[key as keyof DbPrefsType],
        }),
      ),
    );
    toast.success("Preferences updated");
    await refetch();
  };

  const edit = <T extends keyof DbPrefsType>(key: T, value: DbPrefsType[T]) => {
    setEdited((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    if (!data) return;
    setEdited({});
  }, [data]);

  // on inspiration and blog routes, the user may not be logged in.
  // therefore lets pull currency from their geography, and set prefs to be lowest
  const { data: localStorageCountry } = useUserCountry();

  return (
    <DbPrefsCtx.Provider
      value={{
        currency: getCurrency(data?.country ?? localStorageCountry ?? "US"),
        prefs: data,
        edited,
        warnings,
        mutate,
        edit,
        resetEdits: () => setEdited({}),
      }}
    >
      {children}
    </DbPrefsCtx.Provider>
  );
};

export const usePrefsCtx = () => {
  const ctx = useContext(DbPrefsCtx);
  if (!ctx) {
    throw new Error("useDbPrefs must be used within a DbPrefsProvider");
  }
  return ctx;
};

export const usePrefEdit = <T extends keyof DbPrefsType>(
  key: T,
  defaultValue: DbPrefsType[T],
) => {
  const { prefs, edited, edit } = usePrefsCtx();
  const value = edited[key] ?? prefs?.[key] ?? defaultValue;
  return [value, (v: DbPrefsType[T]) => edit(key, v)] as const;
};
