export const ToastErrorGeneral = () => (
  <div className="text-sm">
    <p className="font-medium">Sorry, something went wrong.</p>
    <p className="text-muted-foreground">
      Please try again or contact support.
    </p>
  </div>
);

interface ToastErrorProps {
  title: string;
  subtitle?: string;
}

export const ToastError = ({ title, subtitle }: ToastErrorProps) => (
  <div className="text-sm">
    <p className="font-medium">{title}</p>
    {subtitle && <p className="text-muted-foreground">{subtitle}</p>}
  </div>
);
