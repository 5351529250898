import { env } from "~/env.mjs";

type FetchGetParams = Record<string, string | number | null | undefined>;

export const fetchBE = { get, post };

async function get(route: string, params: FetchGetParams = {}) {
  const url = new URL(env.NEXT_PUBLIC_API_URL + route);
  for (const key in params) {
    const value = params[key];
    if (value === undefined) continue;
    if (value === null) url.searchParams.set(key, "");
    else url.searchParams.set(key, value.toString());
  }
  const response = await fetch(url.toString());
  if (!response.ok) throw new Error(`Error fetching ${route}`);
  return (await response.json()) as unknown;
}

async function post(route: string, body: Record<string, unknown>) {
  const url = new URL(env.NEXT_PUBLIC_API_URL + route);
  const response = await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) throw new Error(`Error fetching ${route}`);
  return (await response.json()) as unknown;
}
