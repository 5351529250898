import { useMutation, useQuery } from "@tanstack/react-query";
import { signIn } from "next-auth/react";
import { FBQ, Mixpanel } from "~/components/tracking-provider";
import { apiBE } from "~/lib/api_be";
import { api } from "~/utils/api";

interface SignUpParams {
  name: string;
  email: string;
  source: string;
}

interface SignUpConfig {
  callbackUrl?: string;
  redirect?: boolean;
}

export const useSignUp = (config: SignUpConfig) => {
  const signUp = api.user.signUp.useMutation();

  const mutation = useMutation({
    mutationFn: async (params: SignUpParams) => {
      const eventId = crypto.randomUUID();
      Mixpanel.track("Sign Up", params);
      FBQ.track("Lead", {}, { eventID: eventId });

      console.log("Sign up", params);

      await signUp.mutateAsync({
        eventId: eventId,
        email: params.email,
        name: params.name,
      });

      const res = await signIn("email", {
        email: params.email,
        callbackUrl: config.callbackUrl,
        redirect: config.redirect,
      });
      if (res?.error) throw new Error(res.error);
      return res;
    },
    onError: (error) => {
      console.error("Error signing up:", error);
    },
  });

  return mutation;
};

export const useUserCountry = () => {
  return useQuery({
    queryKey: ["userCountry"],
    queryFn: async () => {
      const res = await fetch("/api/country");
      console.log("res:", res.ok);
      if (!res.ok) return "";
      const data = (await res.json()) as { country: string };
      console.log("Country:", data.country);
      return data.country;
    },
  });
};

export const useUserImage = (userImageId: string) => {
  return useQuery({
    queryKey: ["userImageUpload", userImageId],
    queryFn: async () => await apiBE.ml.segment(userImageId),
    enabled: !!userImageId,
  });
};
