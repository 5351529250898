import type { DbPrefsType } from "~/components/onboard/db-prefs-provider";
import type { PrefsEditableKeys } from "~/components/onboard/pref-components";

type PrefCheck = (value: DbPrefsType) => boolean;

export const PREF_CHECKS: Record<PrefsEditableKeys, PrefCheck> = {
  motivation: (prefs) => prefs.motivation.length === 0,
  shopping_struggles: (prefs) => prefs.shopping_struggles.length === 0,
  height_cm: (prefs) => !prefs.height_cm,
  body_type: (prefs) => !prefs.body_type,
  skin_tone: (prefs) => !prefs.skin_tone,
  ethnicity: (prefs) => !prefs.ethnicity,
  hair_colour: (prefs) => !prefs.hair_colour,
  age_range: (prefs) => !prefs.age_range,
  styles: (prefs) => prefs.styles.length === 0,
  price_range: (prefs) => !prefs.price_range || !prefs.country,
  colors: (prefs) => prefs.colors.length === 0,
  photo_urls: (prefs) => !prefs.photo_urls?.front || !prefs.photo_urls?.side,
};
