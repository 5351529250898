import { ThemeProvider } from "next-themes";
import Head from "next/head";
import { DbPrefsProvider } from "~/components/onboard/db-prefs-provider";
import { SessionProvider } from "~/components/providers/session-provider";
import { UserActionsProvider } from "~/components/providers/user-actions-provider";
import { SeoMetaProperties } from "~/components/seo-meta-props";
import { TrackingProvider } from "~/components/tracking-provider";
import { FaceSwapProvider } from "../providers/faceswap-provider";
import { UserListsProvider } from "~/components/lists/user-lists-provider";

interface Props {
  children: React.ReactNode;
}

export const DefaultLayout = ({ children }: Props) => {
  return (
    <>
      <Head>
        <title>Glimpses AI</title>
        <SeoMetaProperties />
        {/* prevent zooming on input on mobile */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Head>
      <TrackingProvider>
        <ThemeProvider attribute="class" defaultTheme="light">
          <SessionProvider>
            <DbPrefsProvider>
              <UserActionsProvider>
                <UserListsProvider>
                  <FaceSwapProvider>{children}</FaceSwapProvider>
                </UserListsProvider>
              </UserActionsProvider>
            </DbPrefsProvider>
          </SessionProvider>
        </ThemeProvider>
      </TrackingProvider>
    </>
  );
};
