export interface BoundingBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export function getBoundingBox(coords: number[]): BoundingBox {
  const [x1 = 0, y1 = 0, x2 = 0, y2 = 0] = coords;
  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1,
  };
}
