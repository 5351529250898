const EU_COUNTRY_CODES = new Set([
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
]);

export const getCurrency = (country: string) => {
  if (EU_COUNTRY_CODES.has(country)) {
    return { code: "EUR", symbol: currencySymbolMap.EUR } as const;
  }
  if ("GB" === country) {
    return { code: "GBP", symbol: currencySymbolMap.GBP } as const;
  }
  return { code: "USD", symbol: currencySymbolMap.USD } as const;
};

export const currencySymbolMap = {
  EUR: "€",
  GBP: "£",
  USD: "$",
};

export const getCurrencySymbol = (currency: CurrencyCode) => {
  return currencySymbolMap[currency];
};

export type Currency = ReturnType<typeof getCurrency>;
export type CurrencyCode = Currency["code"];
