/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */
import { MutationCache, QueryCache } from "@tanstack/react-query";
import { httpBatchLink, httpLink, loggerLink, splitLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";
import superjson from "superjson";
import Sentry from "~/lib/sentry";

import { type AppRouter } from "~/server/api/root";

const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      queryClientConfig: {
        // Exception capture from: https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
        mutationCache: new MutationCache({
          onError: (err, _variables, _context, mutation) => {
            Sentry.withScope((scope) => {
              scope.setContext("mutation", {
                mutationId: mutation.mutationId,
                variables: mutation.state.variables,
              });
              if (mutation.options.mutationKey) {
                scope.setFingerprint(
                  Array.from(mutation.options.mutationKey) as string[],
                );
              }
              scope.captureException(err);
            });
          },
        }),
        queryCache: new QueryCache({
          onError: (err, query) => {
            Sentry.withScope((scope) => {
              scope.setContext("query", { queryHash: query.queryHash });
              scope.setFingerprint([query.queryHash.replace(/[0-9]/g, "0")]);
              scope.captureException(err);
            });
          },
        }),
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
          mutations: {},
        },
      },
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === "development" ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        splitLink({
          condition: (op) => op.context?.skipBatch === true,
          true: httpLink({
            url: `${getBaseUrl()}/api/trpc`,
            transformer: superjson,
          }),
          false: httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
            transformer: superjson,
          }),
        }),
      ],
    };
  },
  /**
   * Whether tRPC should await queries when server rendering pages.
   *
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
  transformer: superjson,
});

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;
