import {
  SessionProvider as NextAuthSessionProvider,
  useSession,
} from "next-auth/react";
import { useEffect } from "react";
import { trackingIdentify } from "~/components/tracking-provider";

interface Props {
  children: React.ReactNode;
}

export const SessionProvider = ({ children }: Props) => {
  return (
    <NextAuthSessionProvider>
      <IdentifyInit>{children}</IdentifyInit>
    </NextAuthSessionProvider>
  );
};

const IdentifyInit = ({ children }: Props) => {
  const { data, status } = useSession();
  useEffect(() => {
    if (status === "loading") return;
    if (data?.user) {
      trackingIdentify(data.user);
    }
  }, [status, data?.user]);

  return children;
};
