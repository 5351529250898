export const SeoMetaProperties = () => {
  return (
    <>
      <meta property="og:title" content="Look, Feel, and Dress Better" />
      <meta
        property="og:description"
        content="Explore your style preferences to find items that work for you."
      />
      <meta property="og:url" content="https://glimpses.ai" />
      <meta property="og:site_name" content="Glimpses" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:image" content="https://i.imgur.com/snqi9KH.png" />
      <meta property="og:image:width" content="1120" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Look, Feel, and Dress Better" />
      <meta
        name="twitter:description"
        content="Explore your style preferences to find items that work for you."
      />
      <meta name="twitter:image" content="https://i.imgur.com/snqi9KH.png" />
      <meta
        property="og:image:alt"
        content="Glimpses understands you and your style preferences, and guides you to outfits that work for you."
      />
      <meta property="og:type" content="website" />
    </>
  );
};
