import { useSession } from "next-auth/react";
import React, { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { usePrefsCtx } from "~/components/onboard/db-prefs-provider";
import { ToastError } from "~/components/toast-messages";

type FaceSwapContextType = {
  faceSwapEnabled: boolean;
  setFaceSwapEnabled: (enabled: boolean) => void;
};

export const FaceSwapContext = createContext<FaceSwapContextType | null>(null);

const Storage = {
  faceSwapEnabled: () => {
    if (typeof window === "undefined") return false;
    const enabled = localStorage.getItem("faceSwapEnabled");
    return enabled === "true";
  },
  faceSwapSetEnabled: (enabled: boolean) => {
    if (typeof window === "undefined") return;
    localStorage.setItem("faceSwapEnabled", enabled.toString());
  },
};

export const FaceSwapProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: sess } = useSession();
  const { prefs } = usePrefsCtx();
  const hasPrefs = !!prefs;
  const hasFrontFacePic = !!prefs?.photo_urls?.front;

  const [faceSwapEnabled, setFaceSwapEnabled] = useState(false);

  useEffect(() => {
    if (sess?.user?.plan_type === "FREE") return;
    if (!hasPrefs) return;
    if (!hasFrontFacePic) {
      setFaceSwapEnabled(false);
    }
    setFaceSwapEnabled(Storage.faceSwapEnabled());
  }, [hasPrefs, hasFrontFacePic, sess?.user?.plan_type]);

  const onSetFaceSwapEnabled = (enabled: boolean) => {
    if (sess?.user?.plan_type === "FREE") {
      toast.error(
        <ToastError
          title="Upgrade Required"
          subtitle="Face swap is only available on paid plans."
        />,
      );
      return;
    }
    if (!enabled) {
      Storage.faceSwapSetEnabled(false);
      setFaceSwapEnabled(false);
      return;
    }
    if (!hasFrontFacePic) {
      toast.error(
        <ToastError
          title="Can't enable face swap"
          subtitle="Please visit settings to upload face photos."
        />,
      );
      return;
    }

    Storage.faceSwapSetEnabled(true);
    setFaceSwapEnabled(true);
  };

  return (
    <FaceSwapContext.Provider
      value={{ faceSwapEnabled, setFaceSwapEnabled: onSetFaceSwapEnabled }}
    >
      {children}
    </FaceSwapContext.Provider>
  );
};

export const useFaceSwap = () => {
  const context = React.useContext(FaceSwapContext);
  if (!context) {
    throw new Error("useFaceSwap must be used within a FaceSwapProvider");
  }
  return context;
};
