import { type AppType } from "next/app";
import { Toaster } from "react-hot-toast";
import { DefaultLayout } from "~/components/layout/default-layout";
import type { AppPropsWithLayout } from "~/components/layout/types";
import "~/styles/globals.css";
import { api } from "~/utils/api";

const MyApp: AppType = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout =
    Component.getLayout ?? ((page) => <DefaultLayout>{page}</DefaultLayout>);

  return (
    <>
      {getLayout(
        <>
          <Component {...pageProps} />
          <Toaster position="top-center" />
        </>,
      )}
    </>
  );
};

export default api.withTRPC(MyApp);
