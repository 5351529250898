import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

function getHost() {
  // If there is a specific env variable for the host, use that
  if (process.env.NEXT_PUBLIC_HOST) {
    return process.env.NEXT_PUBLIC_HOST;
  }
  // Else, use the Vercel URL (e.g. preview branches)
  // Though these come without the protocol.
  if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }
  // Default to the production URL
  return "https://glimpses.ai";
}

function getApiUrl() {
  const url = process.env.NEXT_PUBLIC_API_URL;
  // If production, api URL has to be provided
  if (process.env.NEXT_PUBLIC_ENV === "production") {
    return url;
  }
  // Use the Vercel URL (e.g. preview branches) if available
  if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/api/mock`;
  }
  return url;
}

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    // System
    NODE_ENV: z.enum(["development", "test", "production"]),

    // Cron job
    JOB_CRON_DISABLED: z
      .string()
      .optional()
      .transform((value) => value === "true"),
    JOB_MAX_IMG_JOBS: z
      .string()
      .optional()
      .default("1")
      .transform((value) => parseInt(value)),
    JOB_THEME_N_PROMPTS: z
      .string()
      .optional()
      .default("2")
      .transform((value) => parseInt(value)),
    // Number of days that should pass before locking a theme (i.e. queueing for img generation)
    // From theme being created (i.e. options generated)
    JOB_THEME_LOCK_DAYS: z
      .string()
      .optional()
      .default("1")
      .transform((value) => parseInt(value)),
    // Number of days that should pass before releasing a new theme
    // From theme being created (i.e. options generated)
    // Should be greater than JOB_THEME_LOCK_DAYS
    JOB_THEME_RELEASE_DAYS: z
      .string()
      .optional()
      .default("3")
      .transform((value) => parseInt(value)),
    // The number of days that should pass
    // before creating next theme options following a release
    JOB_THEME_NEXT_DAYS: z
      .string()
      .optional()
      .default("2")
      .transform((value) => parseInt(value)),

    // Stripe
    STRIPE_SECRET_KEY: z.string(),
    STRIPE_WEBHOOK_SECRET: z.string(),
    STRIPE_PRICE_ID_FREE: z.string(),
    STRIPE_PRICE_ID_PLUS: z.string(),
    STRIPE_PRICE_ID_PRO: z.string(),

    // Database
    MONGO_URI: z.string(),
    // AWS
    AWS_ACCESS_KEY_ID: z.string(),
    AWS_SECRET_ACCESS_KEY: z.string(),

    // Auth
    NEXTAUTH_SECRET: z.string(),

    // Tracking
    FB_PIXEL_ID: z.string(),
    FB_ACCESS_TOKEN: z.string(),

    // Midjourney
    MJ_SERVER_ID: z.string(),
    MJ_CHANNEL_ID: z.string(),
    MJ_SALAI_TOKEN: z.string(),
    TT_API_KEY: z.string(),
    // OpenAI
    OPENAI_API_KEY: z.string(),
    // Pinterest
    PINTEREST_APP_SECRET: z.string(),

    //Email
    EMAIL_FROM: z.string(),

    // Customer.io
    CUSTOMERIO_APP_API_KEY: z.string(),
    CUSTOMERIO_API_KEY: z.string(),
    CUSTOMERIO_SITE_ID: z.string(),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_HOST: z.string(),
    NEXT_PUBLIC_ENV: z.enum(["development", "staging", "production"]),
    NEXT_PUBLIC_API_URL: z.string(),

    // Stripe
    NEXT_PUBLIC_STRIPE_PORTAL_URL: z.string(),

    // Tracking
    NEXT_PUBLIC_TRACK_DISABLE: z
      .string()
      .optional()
      .transform((value) => value === "true"),
    NEXT_PUBLIC_MIXPANEL_TOKEN: z.string(),

    // Pinterest
    NEXT_PUBLIC_PINTEREST_APP_ID: z.string(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    // System
    NODE_ENV: process.env.NODE_ENV,

    // Cron job
    JOB_CRON_DISABLED: process.env.JOB_CRON_DISABLED,
    JOB_MAX_IMG_JOBS: process.env.JOB_MAX_IMG_JOBS,
    JOB_THEME_N_PROMPTS: process.env.JOB_THEME_N_PROMPTS,
    JOB_THEME_LOCK_DAYS: process.env.JOB_THEME_LOCK_DAYS,
    JOB_THEME_RELEASE_DAYS: process.env.JOB_THEME_RELEASE_DAYS,
    JOB_THEME_NEXT_DAYS: process.env.JOB_THEME_NEXT_DAYS,

    // Stripe
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
    STRIPE_PRICE_ID_FREE: process.env.STRIPE_PRICE_ID_FREE,
    STRIPE_PRICE_ID_PLUS: process.env.STRIPE_PRICE_ID_PLUS,
    STRIPE_PRICE_ID_PRO: process.env.STRIPE_PRICE_ID_PRO,
    NEXT_PUBLIC_STRIPE_PORTAL_URL: process.env.NEXT_PUBLIC_STRIPE_PORTAL_URL,

    // Auth
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,

    // Database
    MONGO_URI: process.env.MONGO_URI,
    // AWS
    AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
    // Email
    EMAIL_FROM: process.env.EMAIL_FROM,

    // Tracking
    FB_PIXEL_ID: process.env.FB_PIXEL_ID,
    FB_ACCESS_TOKEN: process.env.FB_ACCESS_TOKEN,

    // Midjourney
    MJ_SERVER_ID: process.env.MJ_SERVER_ID,
    MJ_CHANNEL_ID: process.env.MJ_CHANNEL_ID,
    MJ_SALAI_TOKEN: process.env.MJ_SALAI_TOKEN,
    TT_API_KEY: process.env.TT_API_KEY,
    // OpenAI
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    // Pinterest
    PINTEREST_APP_SECRET: process.env.PINTEREST_APP_SECRET,
    // Customer.io
    CUSTOMERIO_APP_API_KEY: process.env.CUSTOMERIO_APP_API_KEY,
    CUSTOMERIO_API_KEY: process.env.CUSTOMERIO_API_KEY,
    CUSTOMERIO_SITE_ID: process.env.CUSTOMERIO_SITE_ID,

    // Client
    NEXT_PUBLIC_HOST: getHost(),
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_API_URL: getApiUrl(),
    NEXT_PUBLIC_TRACK_DISABLE: process.env.NEXT_PUBLIC_TRACK_DISABLE,
    NEXT_PUBLIC_MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
    NEXT_PUBLIC_PINTEREST_APP_ID: process.env.NEXT_PUBLIC_PINTEREST_APP_ID,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
