import { useSession } from "next-auth/react";
import Link from "next/link";
import { createContext, useContext, useEffect, useState } from "react";
import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import type { RouterInputs, RouterOutputs } from "~/utils/api";
import { api } from "~/utils/api";
import { indexByKey } from "~/utils/map";

type EntityType = RouterInputs["actions"]["set"]["entityType"];
type UpdateParams = RouterInputs["actions"]["set"];
type UserAction = RouterOutputs["actions"]["list"][number];

type UserActionsContextType = {
  actions: Map<string, UserAction>;
  update: (params: UpdateParams) => void;
};

const UserActionsContext = createContext<UserActionsContextType | null>(null);

function resolveActionUpdate(
  prev: UserAction | undefined,
  params: UpdateParams,
): UserAction {
  function reaction() {
    if (params.action === "LIKE") return "LIKE";
    if (params.action === "DISLIKE") return "DISLIKE";
    if (params.action === "UNREACT") return null;
    return prev?.reaction ?? null;
  }
  function bookmark() {
    if (params.action === "BOOKMARK") return new Date();
    if (params.action === "UNBOOKMARK") return null;
    return prev?.bookmarked_at ?? null;
  }

  return {
    entity_id: params.entityId,
    entity_type: params.entityType,
    reaction: reaction(),
    reaction_at: new Date(),
    bookmarked_at: bookmark(),
  };
}

export const UserActionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { status } = useSession();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [actions, setActions] = useState<Map<string, UserAction>>(new Map());
  const { data } = api.actions.list.useQuery(undefined, {
    enabled: status === "authenticated",
  });
  const mutate = api.actions.set.useMutation();

  useEffect(() => {
    if (!data) return;
    const m = indexByKey(data, (v) => v.entity_id);
    setActions(m);
  }, [data]);

  const update = (params: UpdateParams) => {
    if (status !== "authenticated") {
      setDialogOpen(true);
      return;
    }

    void mutate.mutate(params);

    setActions((prev) => {
      const updated = new Map(prev);
      updated.set(
        params.entityId,
        resolveActionUpdate(prev.get(params.entityId), params),
      );
      return updated;
    });
  };

  return (
    <UserActionsContext.Provider value={{ actions, update }}>
      {children}
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Sign Up to Glimpses</DialogTitle>
            <DialogDescription>
              Complete the onboarding to receive personalized recommendations
              and save your favorite items.
            </DialogDescription>
            <DialogFooter className="pt-4">
              <Link href="/#outfits" passHref>
                <Button onClick={() => setDialogOpen(false)}>Sign Up</Button>
              </Link>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </UserActionsContext.Provider>
  );
};

export const useEntityAction = (id: string, type: EntityType) => {
  const ctx = useContext(UserActionsContext);
  if (!ctx) {
    throw new Error(
      "useEntityAction must be used within a UserActionsProvider",
    );
  }

  const action = ctx.actions.get(id);
  return {
    reaction: action?.reaction ?? null,
    bookmarked: action?.bookmarked_at ? true : false,
    takeAction: (actionType: UpdateParams["action"]) => {
      if (actionType === "LIKE" && action?.reaction === "LIKE") {
        actionType = "UNREACT";
      }
      if (actionType === "DISLIKE" && action?.reaction === "DISLIKE") {
        actionType = "UNREACT";
      }
      if (actionType === "BOOKMARK" && action?.bookmarked_at) {
        actionType = "UNBOOKMARK";
      }
      ctx.update({ entityId: id, entityType: type, action: actionType });
    },
  };
};
